import React from 'react';
import { Route } from 'react-router';
import get from 'lodash/get';

import { WaitingComponent } from '../../../helpers/waitingComponent';
import AuthHelper from '../../../helpers/auth';
import lazy from '../../../helpers/lazy';
import ErrorBoundary from '../../../components/error-boundary/ErrorBoundary';
import {
    APP_PATH_KEY,
    ADMIN_GROUPS_LIST,
    RESOURCES_GROUPS_LIST,
    USER_GROUPS_LIST,
} from './constants';

const Selector = lazy(() => import('./'));
const WingmanKitRequest = lazy(() => import('./WingmanKitRequest'));
const Resources = lazy(() => import('./Resources/Results'));
const Locations = lazy(() => import('./Locations/Results'));
const LocationsDetails = lazy(() => import('./Locations/Details'));
const Students = lazy(() => import('./Students/Results'));
const StudentsDetails = lazy(() => import('./Students/Details'));
const SalesForms = lazy(() => import('./SalesForms/Results'));
const SalesFormsDetails = lazy(() => import('./SalesForms/Details'));

const Router = ({ path }) => {
    const user = AuthHelper.getUser();

    const userGroupId = get(user, 'group._id');

    const isUserGroup = USER_GROUPS_LIST.includes(userGroupId);

    const isAdminGroup = ADMIN_GROUPS_LIST.includes(userGroupId);

    const isResourceGroup = RESOURCES_GROUPS_LIST.includes(userGroupId);

    return isUserGroup || isAdminGroup ? (
        <ErrorBoundary context="Wingman">
            <Route
                path={`${path}/${APP_PATH_KEY}`}
                render={({ match: { url } }) => (
                    <>
                        {isUserGroup || isAdminGroup ? (
                            <>
                                <Route
                                    exact
                                    key="wingmanSelector"
                                    path={`${url}/`}
                                    component={WaitingComponent(Selector)}
                                />
                                <Route
                                    exact
                                    key="wingmanKitRequest"
                                    path={`${url}/kit-request`}
                                    component={WaitingComponent(
                                        WingmanKitRequest
                                    )}
                                />
                            </>
                        ) : null}
                        {isResourceGroup ? (
                            <>
                                <Route
                                    exact
                                    key="wingmanResources"
                                    path={`${url}/resources`}
                                    component={WaitingComponent(Resources)}
                                />
                            </>
                        ) : null}
                        {isAdminGroup ? (
                            <>
                                <Route
                                    exact
                                    key="wingmanLocations"
                                    path={`${url}/locations`}
                                    component={WaitingComponent(Locations)}
                                />
                                <Route
                                    exact
                                    key="wingmanLocationsDetails"
                                    path={`${url}/locations/:itemId`}
                                    component={WaitingComponent(
                                        LocationsDetails
                                    )}
                                />
                                <Route
                                    exact
                                    key="wingmanStudents"
                                    path={`${url}/reporting`}
                                    component={WaitingComponent(Students)}
                                />
                                <Route
                                    exact
                                    key="wingmanStudentsDetails"
                                    path={`${url}/reporting/:itemId`}
                                    component={WaitingComponent(
                                        StudentsDetails
                                    )}
                                />
                                <Route
                                    exact
                                    key="wingmanSalesForms"
                                    path={`${url}/sales-forms`}
                                    component={WaitingComponent(SalesForms)}
                                />
                                <Route
                                    exact
                                    key="wingmanSalesFormsDetails"
                                    path={`${url}/sales-forms/:itemId`}
                                    component={WaitingComponent(
                                        SalesFormsDetails
                                    )}
                                />
                            </>
                        ) : null}
                    </>
                )}
            />
        </ErrorBoundary>
    ) : null;
};

export default Router;
