export const APP_PATH_KEY = 'wingman';

const ADMIN_GROUP_ID = process.env.REACT_APP_ADMIN_GROUP_ID;
const SUPPORT_GROUP_ID = process.env.REACT_APP_KFS_SUPPORT_GROUP_ID;
const CORE_GROUP_ID = process.env.REACT_APP_CORE_GROUP_ID;
const SALES_MANAGERS_GROUP_ID = process.env.REACT_APP_SALES_MANAGERS_GROUP_ID;

export const USER_GROUPS_LIST = [
    ADMIN_GROUP_ID,
    SALES_MANAGERS_GROUP_ID,
    SUPPORT_GROUP_ID,
    CORE_GROUP_ID,
];

export const ADMIN_GROUPS_LIST = [ADMIN_GROUP_ID, SUPPORT_GROUP_ID];

export const RESOURCES_GROUPS_LIST = [ADMIN_GROUP_ID, SALES_MANAGERS_GROUP_ID];
